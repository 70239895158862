<template>
    <div class="">
        <!-- Content Header (Page header) -->
        <div class="">
           <!-- <button class="btn btn-primary" @click="showText= !showText">Click Me</button> -->
            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <!-- Main row -->
                    <div class="row">
                        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                           <div class="card ">
                               <div class="card-body table-responsive table-head">
                                 <div class="row bor-bot">
                                    <div class="col-md-8 pl-0">
                                       <h1 class="m-0 text-dark pt-2 pb-4 ">Individual Registrations List</h1>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="text-right pb-3">
                                            <ol class="breadcrumb float-sm-right">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                            </ol>
                                    </div>
                                    </div>
                                  </div>
                                   <div class="table-responsive mt-3">
                                        <table class="table table-sm table-bordered table-hover" id="list_table">
                                            <thead class="thead-dark">
                                            <tr>
                                                <th>Member ID</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Name</th>
                                                <th>E-Mail</th>
                                                <th>DOB</th>
                                                <th>Gender</th>
                                                <th>Magazine Subscription</th>
                                                <th>Membership</th>
                                                <th>Status</th>
                                                <th>Date Paid</th>
                                                <th>Start Date</th>
                                                <th>Expiration On</th>
                                                <th>Waiver Signed On</th>
                                                <th>Waiver Expiration On</th>
                                                <th>Transaction ID</th>
                                                <th>Region</th>
                                                <th>Team Name</th>
                                                <th>Age Division</th>
                                                <th>Coupon Code</th>
                                                <th>Actions</th>
                                                <th>Created At</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <!-- Data will be placed here -->
                                            <tr>
                                                <td colspan="9">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="9">&nbsp;</td>                                                
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                               </div>
                           </div>
                        </section>
                    </div>
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
        </div>
        <!-- /.content -->
        <Footer></Footer>
    </div>
</template>

<script>

// import RegistrationsListTable from './RegistrationsListTable';
import $ from 'jquery';
// import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import 'datatables.net-responsive-dt';
import moment from 'moment';


export default {
    name: 'registrations',
    components: { },
    data() {
        return{
          table:''
        }
    },
    methods: {
        formatDate(value) {
            var date=  moment(value, "YYYY-MM-DD hh:mm:ss");
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },
        deleteRegistration(id){
                if(confirm("Are you sure you want to delete?")){
                    fetch(process.env.VUE_APP_URL + "api/registrations/delete/"+id,{
                        method:'delete',
                      headers: this.adminHeaders,
                    })
                    .then(res => res.json())
                    .then(data => {
                      this.alertMessage = "Record deleted successfully";
                      this.displayAlert = true;
                      this.table.draw();
                        //  dt.row($(this).parents('tr')).remove().draw(true);
                    })
                    .catch(err => console.log(err));
                }
           }
    },
    mounted(){
        // $.ajaxSetup({
        //     headers: {
        //         'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        //         'ClientId': this.sassClientIds[this.subdomain]
        //     }
        // });
        window.reg=this;
        let orderby = (this.subdomain == 'alliancefastpitch') ? 18 : 17;
        this.table = $("#list_table").DataTable({
            dom: 'lfrtip',
            "bFilter": true,
            processing: true,
            serverSide: true,
            pageLength: 10,
            rowReorder: {
            selector: 'td:nth-child(2)'
            },
            responsive: true,
            ajax: {
                url: process.env.VUE_APP_URL + "api/dashboard/registrations/list/Individual",
                type: 'post'
            },
            columns: [
                { data: 'confirmation_code', name: 'member.confirmation_code', render: (data)=>{return data || "N/A";}},
                { data: "first_name", name: "personal_details.first_name", visible: false, render: (data)=>{return data || "N/A";} },
                { data: "last_name", name: "personal_details.last_name", visible: false, render: (data)=>{return data || "N/A";} },
                { data: "full_name", name: "personal_details.first_name", render: (data)=>{return data || "N/A";} },
                { data: "email", name: "contact_details.email", render: (data)=>{return '<a class="email-link" href="mailto:'+ data +'">'+ data +'</a>' || "N/A";}},
                { data: "dob", name: "personal_details.dob", orderable: false, searchable: false, render: (data)=>{return data || "N/A";} },
                { data: "gender", name: "personal_details.gender", render: (data)=>{return data || "N/A";} },
                { data: "member.printed_magazine", searchable: false, visible: this.subdomain == "waterski", name: "Magazine Subscription", orderable:false, render: (data)=>{return (data == 0 || data == null)?'Yes':'No' || "N/A"}  },
                { data: "membership_type", name: "membership.name", render: (data)=>{return data || "N/A";} },
                { data: "reg_status", name: "reg_status", orderable: false, searchable: false, render: (data)=>{return data || "N/A";} },
                { data: "transaction_date", name: "transaction_date", render: (data)=>{return this.formatDate(data)} },
                { data: "r_start_date", name: "registration.start_date", render: (data)=>{return this.formatDate(data)} },
                { data: "expired_at", name: "registration.valid_thru", render: (data)=>{return this.formatDate(data)} },
                { data: "waiver_signed_at", name: "registration.valid_thru", render: (data)=>{return data || "N/A";} },
                { data: "waiver_expired_at", name: "registration.valid_thru", render: (data)=>{return data || "N/A";} },
                { data: "transaction_id", name: "registration.transaction_id", render: (data)=>{return data || "N/A";} },
                { data: "region_code", name: "region.code", render: (data)=>{return data || "N/A";}, visible: this.subdomain == "waterski", searchable: false },
                { data: "member_team_name", name: "member_team_name", render: (data)=>{return data || "N/A";}, visible: this.subdomain == "alliancefastpitch", searchable: false },
                { data: "age_division", name: "age_division", render: (data)=>{return data || "N/A";}, visible: this.subdomain == "alliancefastpitch", searchable: false },
                { data: "coupon_code", name: "coupon_code", render: (data)=>{return data || "N/A";}, visible: this.subdomain == "waterski" },
                { data: "actions", name: "actions", orderable: false, searchable: false,
                // render: () => {
                //   return ``
                // } ,
                render: (data,type,row,meta) => {
                    let btn = '';
                    if(this.getSiteProps('general.scoring')){
                        btn += `<a class="primary-text" href="/admin/member/${row.confirmation_code}/score/${row.member_id}" title="Shooting Score"><i class="fas fa-trophy"></i></a> `
                    }
                    btn += `<a  href="/admin/registrations/${row.member_id}"><i class="fas fa-eye"></i></a> <a class="action-delete" data-id="${row.id}" href="javascript:void(0);"><i class="fas fa-trash"></i></a>`
                    return btn
                }
               },
                { data: "created_at", name: "created_at", render: (data)=>{return data || "N/A";}, visible: false, searchable: false },
            ],
            columnDefs: [
                this.subdomain == 'waterski'?{ targets: 10, orderable: false, searchable: false }:{ targets: 9, orderable: false, searchable: false },
                { targets: 16, visible: this.subdomain == "waterski" },
            ],
            order: [[orderby, "desc"]]
        });
       var vueRouter = this.$router;
        document.addEventListener("click", function(e){
            if(e.target && e.target.parentNode.className == "actions-click"){
                var id = e.target.parentNode.getAttribute('data-id');
                vueRouter.push('/admin/registrations/'+ id);
            }
            if(e.target && e.target.parentNode.className == "action-delete"){
                var deleteId = e.target.parentNode.getAttribute('data-id');
              window.reg.deleteRegistration(deleteId);
            }
        });
    },

    computed: {

    }
}
</script>